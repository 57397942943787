import request from './request.js'

// 系统角色管理
// 角色管理表
export function systemRoleList(params) {
    return request({
        url: 'system/role/list.do?',
        method: 'post',
        data:params
    })
}

// 添加用户数据资源
export function addUserResourceList(params) {
    return request({
        url: 'system/resource/resourceList.do?',
        method: 'post',
        data:params
    })
}

// 添加角色
export function addSystemRole(params) {
    return request({
        url: 'system/role/save.do?',
        method: 'post',
        data:params
    })
}

// 修改角色资源表
export function editRoleTreeTable(params) {
    return request({
        url: 'system/resource/getAllTree.do?',
        method: 'post',
        data:params
    })
}


// 获取已勾选项
export function roleCheckedItem(params) {
    return request({
        url: 'system/role/getRoleTree.do?',
        method: 'post',
        data:params
    })
}

// 更新角色修改
export function updateRole(params) {
    return request({
        url: 'system/role/update.do?',
        method: 'post',
        data:params
    })
}

// 更新角色状态
export function updateRoleStatus(params) {
    return request({
        url: 'system/role/updateStatus.do?',
        method: 'post',
        data:params
    })
}

// 代理商角色管理
// 获取上及代理商角色列表
export function agentRoleList(params) {
    return request({
        url: 'system/role/roleList.do?',
        method: 'post',
        data:params
    })
}

// 编辑获取角色表
export function updateRoleForm(params) {
    return request({
        url: 'system/role/updateForm.do?',
        method: 'post',
        data:params
    })
}

// 管理员管理
// 管理员表
export function adminUser(params) {
    return request({
        url: 'system/adminUser/list.do?',
        method: 'post',
        data:params
    })
}

// 获取已勾选数据

export function updateFormAdminUser(params) {
    return request({
        url: 'system/adminUser/updateForm.do?',
        method: 'post',
        data:params
    })
}

// 添加管理员数据

export function addAdminUser(params) {
    return request({
        url: 'system/adminUser/save.do?',
        method: 'post',
        data:params
    })
}

// 修改管理员数据
export function updateAdminUser(params) {
    return request({
        url: 'system/adminUser/update.do?',
        method: 'post',
        data:params
    })
}

// 修改密码
export function updatePassword(params) {
    return request({
        url: 'system/adminUser/updatePassword.do?',
        method: 'post',
        data:params
    })
}

// 修改状态
export function updateAdminStatus(params) {
    return request({
        url: 'system/adminUser/updateStatus.do?',
        method: 'post',
        data:params
    })
}

// 接口权限管理
export function interfaceAuthorityTree(params) {
    return request({
        url: 'system/resource/getTree.do?',
        method: 'post',
        data:params
    })
}

// 全局添加
export function interfaceAddTree(params) {
    return request({
        url: 'system/resource/addTree.do?',
        method: 'post',
        data:params
    })
}

// 编辑
export function interfaceEditTree(params) {
    return request({
        url: 'system/resource/editTree.do?',
        method: 'post',
        data:params
    })
}

// 删除
export function interfaceDeleteTree(params) {
    return request({
        url: 'system/resource/delTree.do?',
        method: 'post',
        data:params
    })
}

// 公告
export function adminAnnounceList(params) {
    return request({
        url: '/announce/admin/list.do?',
        method: 'post',
        data:params
    })
}

export function adminAnnounceAdd(params) {
    return request({
        url: '/announce/admin/insert.do?',
        method: 'post',
        data:params
    })
}

export function adminAnnounceDel(params) {
    return request({
        url: '/announce/admin/delete.do?',
        method: 'post',
        data:params
    })
}

export function adminAnnounceEdit(params) {
    return request({
        url: '/announce/admin/edit.do?',
        method: 'post',
        data:params
    })
}

export function agentAnnounceList(params) {
    return request({
        url: '/announce/agent/list.do?',
        method: 'post',
        data:params
    })
}

export function agentAnnounceRead(params) {
    return request({
        url: '/announce/agent/read.do?',
        method: 'post',
        data:params
    })
}

export function getConfigurationModify(params) {//获取配置信息
    return request({
        url: '/system/config/get.do?',
        method: 'post',
        data:params
    })
}
export function setConfigurationModify(params) {//设置配置信息
    return request({
        url: '/system/config/set.do?',
        method: 'post',
        data:params
    })
}

export function uploadFile(params) {
    return request({
       url:"/pay/upload.do",
        method: 'post',
        data: params,
				timeout:20000,
				headers:{'Content-Type': 'multipart/form-data'}
				
    })
}
export function getDictManageList(params) {//获取字典组列表
    return request({
       url:"/dict/list.do?",
        method: 'post',
        data: params,
    })
}

export function delDictManageList(params) {//删除字典组
    return request({
       url:"/dict/deleteById.do?",
        method: 'post',
        data: params,
    })
}
export function addDictManageList(params) {//新增字典组
    return request({
       url:"/dict/insertDict.do?",
        method: 'post',
        data: params,
    })
}
export function editDictManageList(params) {//新增字典组
    return request({
       url:"/dict/updateDict.do?",
        method: 'post',
        data: params,
    })
}
export function getDictGroup(params) {//获取字典组列表
    return request({
       url:"/dict/getGroupList.do?",
        method: 'post',
        data: params,
    })
}

export function addVerifyFile(params) {
    return request({
        url:"/system/config/addVerify.do?",
        method: 'post',
        data: params,
    })
}

// carrierPriceImport

export function getCarrierPriceList(params) {//获取列表
    return request({
        url:"/accountPrice/getList.do?",
        method: 'post',
        data: params,
    })
}
export function deleteAccountPrice(params) {//删除
    return request({
        url:"/accountPrice/del.do?",
        method: 'post',
        data: params,
    })
}

export function updateAccountPrice(params) {//编辑
    return request({
        url:"/accountPrice/update.do?",
        method: 'post',
        data: params,
    })
}

export function addAccountPrice(params) {//新增
    return request({
        url:"/accountPrice/insert.do?",
        method: 'post',
        data: params,
    })
}
//参数列表
export function getParamsList(params) {//
    return request({
        url:"/system/config/list.do?",
        method: 'post',
        data: params,
    })
}

export function editParams(params) {//编辑
    return request({
        url:"/system/config/update.do?",
        method: 'post',
        data: params,
    })
}

export function addParams(params) {//新增
    return request({
        url:"/system/config/add.do?",
        method: 'post',
        data: params,
    })
}

export function deleParams(params) {//删除
    return request({
        url:"/system/config/delete.do?",
        method: 'post',
        data: params,
    })
}
//操作日志



export function operatorLoglist(params) {//获取列表
    return request({
        url:"/operateLog/list.do?",
        method: 'post',
        data: params,
    })
}

export function operatorlogExport(params) {//获取列表
    return request({
        url:"/operateLog/exportExcelSync.do?",
        method: 'post',
        data: params,
    })
}


export function informationOnOfficialSiteList(params) {//获取列表
    return request({
        url:"/gyCustomer/list.do?",
        method: 'post',
        data: params,
    })
}

export function gyCustomerExport(params) {//获取列表
    return request({
        url:"gyCustomer/exportExcelSync.do?",
        method: 'post',
        data: params,
    })
}
export function restartMonitor(params) {//重启monitor
    return request({
        url:"/system/operation/restartMonitor.do?",
        method: 'post',
        data: params,
    })
}
export function getAllList(params) {//销售列表
    return request({
        url:"/batch/sale/allList.do?",
        method: 'post',
        data: params,
    })
}














